import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";

export default function Home() {
    return (
        <Box padding={2}>
            <Typography variant="h1" >Kaeshev Alapati</Typography>
            <Typography variant="body1" align="left">Website construction in progress!</Typography>
            <Box alignItems={"center"}
                component="img"
                // sx={{
                //     height: 233,
                //     width: 350,
                //     maxHeight: { xs: 233, md: 167 },
                //     maxWidth: { xs: 350, md: 250 },
                // }}
                alt="Source: https://www.deviantart.com/minionfan1024/art/Minion-under-construction-802818590"
                src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/e2740e4b-67b5-4fb7-8971-1ad83c7cb6b2/dd9z6se-959fd99f-9ce8-4d62-8969-bf5ce809e0b8.png"
            />
        </Box>
    )
}