import React, {useState} from 'react';
import './App.css';

import {Box, Grid} from "@mui/material";

import '@fontsource/dm-sans/300.css'
import '@fontsource/dm-sans/400.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'

import SidePanel from "./components/side-panel";
import Home from "./components/home";

import {Skills} from "./components/skills";

function App() {
    const [currentPage, setCurrentPage] = useState('Home')

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                <Grid item xs={3.5}>
                    <SidePanel setClicked={setCurrentPage}/>
                </Grid>
                <Grid item xs={.1}></Grid>
                <Grid item xs={8}>
                    {currentPage === 'Home' && <Home/>}
                    {currentPage === 'Skills' && <Skills/>}
                </Grid>
            </Grid>
        </Box>
    );
}

export default App;
